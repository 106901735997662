<template>
    <BaseLayout head-title="Нічого не знайдено">
        <section class="py-24">
            <div class="container px-4 mx-auto">
                <div class="max-w-xl mx-auto text-center">
                  <span class="inline-block mx-auto mb-6 bg-red-400 rounded-full p-5 text-white">
                      <Cross width="40"/>
                  </span>
                    <h3 class="text-2xl font-black mb-5">Ой, ми нічого не знайшли 😟</h3>

                    <p class="text-lg font-bold mb-12">Можеш повернутись на головну і обрати щось цікаве</p>

                    <Link href="/">
                        <PrimaryButton color="green" hover-color="green" text-color="black" class="max-w-sm">
                            На головну
                        </PrimaryButton>
                    </Link>
                </div>
            </div>
        </section>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import Cross from "@/Components/Svg/Cross.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {Link} from "@inertiajs/vue3";

export default {
    components: {Link, PrimaryButton, Cross, BaseLayout}
}
</script>
